define("cac-components/components/image-uploader/image-capture/video/component", ["exports", "cac-components/components/image-uploader/image-capture/video/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',
    stream: null,
    loadedVideo: null,
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      var constraints = {
        video: {
          facingMode: 'environment'
        },
        audio: false
      };
      navigator.mediaDevices.getUserMedia(constraints).then(function (stream) {
        if (!_this.isDestroyed) {
          _this.set('stream', stream);
        }
      });
    },
    actions: {
      onPlaying: function onPlaying(e) {
        this.set('loadedVideo', e.target);
      }
    }
  });

  _exports.default = _default;
});