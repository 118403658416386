define("cac-components/components/x-tabs/pane/component", ["exports", "cac-components/components/x-tabs/pane/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    name: null,
    active: null
  }).reopenClass({
    positionalParams: ['name']
  });

  _exports.default = _default;
});