define("ember-infinity/components/infinity-loader", ["exports", "ember-in-viewport"], function (_exports, _emberInViewport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var InfinityLoaderComponent = Ember.Component.extend(_emberInViewport.default, {
    infinity: Ember.inject.service(),
    classNames: ['infinity-loader'],
    classNameBindings: ['isDoneLoading:reached-infinity', 'viewportEntered:in-viewport'],
    attributeBindings: ['data-test-infinity-loader'],
    'data-test-infinity-loader': true,

    /**
     * @public
     * @property eventDebounce
     * @default 50
     */
    eventDebounce: 50,

    /**
     * @public
     * @property loadingText
     */
    loadingText: 'Loading Infinity Model...',

    /**
     * @public
     * @property loadedText
     */
    loadedText: 'Infinity Model Entirely Loaded.',

    /**
     * @public
     * @property hideOnInfinity
     * @default false
     */
    hideOnInfinity: false,

    /**
     * @public
     * @property isDoneLoading
     * @default false
     */
    isDoneLoading: false,

    /**
     * @public
     * @property developmentMode
     * @default false
     */
    developmentMode: false,

    /**
     * indicate to infinity-loader to load previous page
     *
     * @public
     * @property loadPrevious
     * @default false
     */
    loadPrevious: false,

    /**
     * set if have scrollable area
     *
     * @public
     * @property scrollable
     */
    scrollable: null,

    /**
     * offset from bottom of target and viewport
     *
     * @public
     * @property triggerOffset
     * @defaul 0
     */
    triggerOffset: 0,

    /**
     * https://emberjs.com/api/ember/3.0/classes/Component/properties/isVisible?anchor=isVisible
     *
     * @property isVisible
     */
    isVisible: true,
    init: function init() {
      this._super.apply(this, arguments);

      var scrollableArea = Ember.get(this, 'scrollable');
      this.setProperties({
        viewportSpy: true,
        viewportTolerance: {
          top: 0,
          right: 0,
          bottom: Ember.get(this, 'triggerOffset'),
          left: 0
        },
        scrollableArea: scrollableArea
      });
    },
    willInsertElement: function willInsertElement() {
      Ember.defineProperty(this, 'infinityModelContent', Ember.computed('infinityModel', function () {
        return Ember.RSVP.resolve(Ember.get(this, 'infinityModel'));
      }));
      this.addObserver('infinityModel', this, this._initialInfinityModelSetup);
    },

    /**
     * setup ember-in-viewport properties
     *
     * @method didInsertElement
     */
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._loadStatusDidChange();

      this._initialInfinityModelSetup();

      this.addObserver('hideOnInfinity', this, this._loadStatusDidChange);
    },
    willDestroyElement: function willDestroyElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this._cancelTimers();

      Ember.get(this, 'infinityModelContent').then(function (infinityModel) {
        infinityModel.off('infinityModelLoaded', _this, _this._loadStatusDidChange);
      });
      this.removeObserver('infinityModel', this, this._initialInfinityModelSetup);
      this.removeObserver('hideOnInfinity', this, this._loadStatusDidChange);
    },

    /**
     * https://github.com/DockYard/ember-in-viewport#didenterviewport-didexitviewport
     *
     * @method didEnterViewport
     */
    didEnterViewport: function didEnterViewport() {
      if (Ember.get(this, 'developmentMode') || typeof FastBoot !== 'undefined' || this.isDestroying || this.isDestroyed) {
        return false;
      }

      if (Ember.get(this, 'loadPrevious')) {
        return this._debounceScrolledToTop();
      }

      return this._debounceScrolledToBottom();
    },

    /**
     * https://github.com/DockYard/ember-in-viewport#didenterviewport-didexitviewport
     *
     * @method didExitViewport
     */
    didExitViewport: function didExitViewport() {
      this._cancelTimers();
    },

    /**
     * @method _initialInfinityModelSetup
     */
    _initialInfinityModelSetup: function _initialInfinityModelSetup() {
      var _this2 = this;

      Ember.get(this, 'infinityModelContent').then(function (infinityModel) {
        infinityModel.on('infinityModelLoaded', _this2, _this2._loadStatusDidChange);
        Ember.set(infinityModel, '_scrollable', Ember.get(_this2, 'scrollable'));
        Ember.set(_this2, 'isDoneLoading', false);

        if (!Ember.get(_this2, 'hideOnInfinity')) {
          Ember.set(_this2, 'isVisible', true);
        }
      });
    },

    /**
     * @method _loadStatusDidChange
     */
    _loadStatusDidChange: function _loadStatusDidChange() {
      var _this3 = this;

      Ember.get(this, 'infinityModelContent').then(function (infinityModel) {
        if (Ember.get(infinityModel, 'reachedInfinity')) {
          Ember.set(_this3, 'isDoneLoading', true);

          if (Ember.get(_this3, 'hideOnInfinity')) {
            Ember.set(_this3, 'isVisible', false);
          }
        } else {
          Ember.set(_this3, 'isVisible', true);
        }
      });
    },

    /**
     * only load previous page if route started on a page greater than 1 && currentPage is > 0
     *
     * @method _debounceScrolledToTop
     */
    _debounceScrolledToTop: function _debounceScrolledToTop() {
      var _this4 = this;

      /*
       This debounce is needed when there is not enough delay between onScrolledToBottom calls.
       Without this debounce, all rows will be rendered causing immense performance problems
       */
      function loadPreviousPage(content) {
        if (typeof Ember.get(this, 'infinityLoad') === 'function') {
          // closure action
          return Ember.get(this, 'infinityLoad')(content, -1);
        } else {
          Ember.get(this, 'infinity').infinityLoad(content, -1);
        }
      }

      Ember.get(this, 'infinityModelContent').then(function (content) {
        if (Ember.get(content, 'firstPage') > 1 && Ember.get(content, 'currentPage') > 0) {
          _this4._debounceTimer = Ember.run.debounce(_this4, loadPreviousPage, content, Ember.get(_this4, 'eventDebounce'));
        }
      });
    },

    /**
     * @method _debounceScrolledToBottom
     */
    _debounceScrolledToBottom: function _debounceScrolledToBottom() {
      /*
       This debounce is needed when there is not enough delay between onScrolledToBottom calls.
       Without this debounce, all rows will be rendered causing immense performance problems
       */
      function loadMore() {
        var _this5 = this;

        // resolve to create thennable
        // type is <InfinityModel|Promise|null>
        Ember.get(this, 'infinityModelContent').then(function (content) {
          if (typeof Ember.get(_this5, 'infinityLoad') === 'function') {
            // closure action (if you need to perform some other logic)
            return Ember.get(_this5, 'infinityLoad')(content);
          } else {
            // service action
            Ember.get(_this5, 'infinity').infinityLoad(content, 1).then(function () {
              if (Ember.get(content, '_canLoadMore')) {
                _this5._checkScrollableHeight();
              }
            });
          }
        });
      }

      this._debounceTimer = Ember.run.debounce(this, loadMore, Ember.get(this, 'eventDebounce'));
    },

    /**
     * recursive function to fill page with records
     *
     * @method _checkScrollableHeight
     */
    _checkScrollableHeight: function _checkScrollableHeight() {
      if (this.isDestroying || this.isDestroyed) {
        return false;
      }

      if (this._viewportHeight() > this.element.offsetTop) {
        // load again
        this._debounceScrolledToBottom();
      }
    },

    /**
     * @method _cancelTimers
     */
    _cancelTimers: function _cancelTimers() {
      Ember.run.cancel(this._debounceTimer);
    },

    /**
      calculate the height of the viewport
       @private
      @method _viewportHeight
      @return Integer
     */
    _viewportHeight: function _viewportHeight() {
      if (typeof FastBoot === 'undefined') {
        var isScrollable = !!this.scrollable;
        var viewportElem = isScrollable ? document.querySelector(this.scrollable) : window;
        return isScrollable ? viewportElem.clientHeight : viewportElem.innerHeight;
      }
    }
  });
  var _default = InfinityLoaderComponent;
  _exports.default = _default;
});