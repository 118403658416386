define("cac-components/components/linked-items/component", ["exports", "cac-components/components/linked-items/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component;

  var _default = Component.extend({
    layout: _template.default,
    classNames: ['linked-items', 'ui'],
    items: null,
    isLoading: null,
    fetch: null
  }).reopenClass({
    positionalParams: ['items']
  });

  _exports.default = _default;
});