define("cac-common/authenticators/application", ["exports", "ember-simple-auth/authenticators/oauth2-password-grant"], function (_exports, _oauth2PasswordGrant) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var getOwner = Ember.getOwner,
      computed = Ember.computed;

  var _default = _oauth2PasswordGrant.default.extend({
    serverTokenEndpoint: computed(function () {
      var ENV = getOwner(this).resolveRegistration('config:environment');
      var config = ENV['cac-common'];
      var host = config.host;
      return host + '/oauth/token';
    })
  });

  _exports.default = _default;
  ;
});