define("ember-get-config/find", ["exports"], function (exports) {
  exports.Array_find = Array_find;
  // Polyfill only as much as we need (don't need thisArg)

  function Array_find(callback) {
    for (var i = 0, entry = this[i]; i < this.length; entry = this[++i]) {
      if (callback(entry, i, this)) {
        return entry;
      }
    }
  }

  exports["default"] = Array.prototype.find || Array_find;
});