define("cac-components/components/linked-items/pagination/component", ["exports", "cac-components/components/linked-items/pagination/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component;

  var _default = Component.extend({
    layout: _template.default
  });

  _exports.default = _default;
});