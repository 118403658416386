define("cac-common/routes/authentication", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin", "cac-common/utils/extract-errors"], function (_exports, _unauthenticatedRouteMixin, _extractErrors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Route = Ember.Route,
      getOwner = Ember.getOwner,
      RSVP = Ember.RSVP,
      inject = Ember.inject,
      get = Ember.get;

  var _default = Route.extend(_unauthenticatedRouteMixin.default, {
    session: inject.service(),
    actions: {
      authenticate: function authenticate(changeset) {
        var _this = this;

        return changeset.validate().then(function () {
          if (get(changeset, 'isInvalid') && get(changeset, 'errors').find(function (error) {
            return get(error, 'key') !== _extractErrors.PRIMARY_ATTRIBUTE_KEY;
          })) {
            return RSVP.reject();
          }
        }).then(function () {
          var ENV = getOwner(_this).resolveRegistration('config:environment');
          var config = ENV['cac-common'];
          var scope = config.scope;
          var username = get(changeset, 'username');
          var password = get(changeset, 'password');
          return get(_this, 'session').authenticate('authenticator:application', username, password, scope || undefined).catch(function (reason) {
            if (reason.error) {
              (0, _extractErrors.default)([reason.error], changeset.addError.bind(changeset));
            }

            return RSVP.reject(reason);
          });
        }).then(function () {
          return _this.transitionTo('/');
        });
      }
    }
  });

  _exports.default = _default;
});