define('ember-get-config/index', ['exports', 'ember-get-config/find'], function (exports, _emberGetConfigFind) {
  exports.findConfigName = findConfigName;

  var CONFIG_REGEX = /^[^/]+\/config\/environment$/;

  function findConfigName(entries) {
    return _emberGetConfigFind['default'].call(Object.keys(entries), function (entry) {
      return CONFIG_REGEX.test(entry);
    });
  }

  exports['default'] = window.requirejs(findConfigName(window.requirejs.entries))['default'];
});