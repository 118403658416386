define("cac-components/components/linked-items/loading/component", ["exports", "cac-components/components/linked-items/loading/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
      _Ember$run = Ember.run,
      later = _Ember$run.later,
      cancel = _Ember$run.cancel,
      get = Ember.get,
      set = Ember.set;

  var _default = Component.extend({
    layout: _template.default,
    classNames: ['linked-items__loading'],
    didInsertElement: function didInsertElement() {
      set(this, 'showLater', later(this, function () {
        this.$().addClass('-shown');
      }, 100));
    },
    willDestroyElement: function willDestroyElement() {
      cancel(get(this, 'showLater'));
    }
  });

  _exports.default = _default;
});