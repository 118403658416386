define("cac-common/components/promising-form/component", ["exports", "cac-common/components/promising-form/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
      set = Ember.set;

  var _default = Component.extend({
    layout: _template.default,
    tagName: 'form',
    promise: null,
    submit: function submit(e) {
      e.preventDefault();
      set(this, 'promise', this.action());
    }
  });

  _exports.default = _default;
});