define("cac-common/mixins/authenticated-route-mixin", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Mixin = Ember.Mixin,
      get = Ember.get;

  var _default = Mixin.create(_authenticatedRouteMixin.default, {
    actions: {
      invalidateSession: function invalidateSession() {
        return get(this, 'session').invalidate();
      }
    }
  });

  _exports.default = _default;
});