define('semantic-ui-ember/components/ui-popup', ['exports', 'ember', 'semantic-ui-ember/mixins/base'], function (exports, _ember, _semanticUiEmberMixinsBase) {
  exports['default'] = _ember['default'].Component.extend(_semanticUiEmberMixinsBase['default'], {
    module: 'popup',

    didInitSemantic: function didInitSemantic() {
      this._super.apply(this, arguments);
      this.get('_settableAttrs').addObjects(['content', 'title', 'html']);
    },

    setSemanticAttr: function setSemanticAttr(attrName, attrValue) {
      if (attrName === 'content' || attrName === 'title' || attrName === 'html') {
        var response = this.execute('setting', attrName, attrValue);
        if (this.execute('is visible')) {
          var html = undefined;
          if (attrName === 'html') {
            html = attrValue;
          } else {
            var text = undefined;
            if (attrName === 'content') {
              text = {
                title: this.get('title'),
                content: attrValue
              };
            } else {
              text = {
                title: attrValue,
                content: this.get('content')
              };
            }
            var moduleGlobal = this.getSemanticModuleGlobal();
            html = moduleGlobal.settings.templates.popup(text);
          }
          this.execute('change content', html);
        }
        return response;
      }
      return this._super.apply(this, arguments);
    }
  });
});