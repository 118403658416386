define("cac-common/services/infinity", ["exports", "ember-infinity/services/infinity"], function (_exports, _infinity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _infinity.default.extend({
    model: function model(modelName, options, modelKlass) {
      options.totalPagesParam = 'meta.page-count';
      options.countParam = 'meta.record-count';
      options.pageParam = 'page[number]';
      options.perPageParam = 'page[size]';
      options.perPage = options.page && options.page.size || 10;
      return this._super(modelName, options, modelKlass);
    }
  });

  _exports.default = _default;
});