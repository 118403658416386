define("cac-components/components/image-uploader/preview/component", ["exports", "cac-components/components/image-uploader/preview/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['image-uploader__preview'],
    didRender: function didRender() {
      if (this.image) {
        var reader = new FileReader();
        var preview = this.$('img')[0];
        reader.addEventListener('load', function () {
          preview.src = reader.result;
        }, false);
        reader.readAsDataURL(this.image);
      }
    }
  });

  _exports.default = _default;
});