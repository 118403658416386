define("cac-common/components/authentication-form/component", ["exports", "ember-changeset", "cac-common/validations/authentication", "ember-changeset-validations"], function (_exports, _emberChangeset, _authentication, _emberChangesetValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
      get = Ember.get;

  var _default = Component.extend({
    classNames: ['authentication-form'],
    username: '',
    init: function init() {
      this._super.apply(this, arguments);

      this.changeset = new _emberChangeset.default({
        username: get(this, 'username'),
        password: ''
      }, (0, _emberChangesetValidations.default)(_authentication.default), _authentication.default);
    },
    didInsertElement: function didInsertElement() {
      this.$('form input').eq(0).focus();
    }
  });

  _exports.default = _default;
});