define('ember-data-live-filter-by/initializers/live-filter', ['exports', 'ember-data-live-filter-by/live-filter-by', 'ember-data'], function (exports, _emberDataLiveFilterByLiveFilterBy, _emberData) {
  exports.initialize = initialize;

  function initialize() /* container, application */{
    _emberData['default'].RecordArray.reopen({
      filterBy: _emberDataLiveFilterByLiveFilterBy['default']
    });
    _emberData['default'].ManyArray.reopen({
      filterBy: _emberDataLiveFilterByLiveFilterBy['default']
    });
  }

  exports['default'] = {
    name: 'livefilter',
    initialize: initialize
  };
});