define('ember-data-live-filter-by/live-filter-by', ['exports', 'ember-data-live-filter-by/filtered-subset'], function (exports, _emberDataLiveFilterByFilteredSubset) {
  exports['default'] = liveFailterBy;

  function liveFailterBy(key, value, options) {
    options = options || {};

    if (options.live) {
      return _emberDataLiveFilterByFilteredSubset['default'].create({
        filterByArgs: [key, value],
        recordArray: this
      });
    }

    return this._super.apply(this, arguments);
  }
});