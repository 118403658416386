define('ember-transition-helper/helpers/transition-to', ['exports', 'ember-transition-helper/utils/transform-query-params'], function (exports, _transformQueryParams2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function _toArray(arr) {
    return Array.isArray(arr) ? arr : Array.from(arr);
  }

  exports.default = Ember.Helper.extend({
    router: Ember.computed(function () {
      return Ember.getOwner(this).lookup('router:main');
    }).readOnly(),

    compute: function compute(_ref) {
      var _ref2 = _toArray(_ref),
          routeName = _ref2[0],
          params = _ref2.slice(1);

      var router = this.get('router');
      (false && !(router) && Ember.assert('[ember-transition-helper] Unable to lookup router', router));

      return function () {
        for (var _len = arguments.length, invocationArgs = Array(_len), _key = 0; _key < _len; _key++) {
          invocationArgs[_key] = arguments[_key];
        }

        var args = params.concat(invocationArgs);
        var transitionArgs = params.length ? [routeName].concat(_toConsumableArray(params)) : [routeName];
        router.transitionTo.apply(router, _toConsumableArray((0, _transformQueryParams2.default)(transitionArgs)));
        return args;
      };
    }
  });
});