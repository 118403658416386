define("cac-common/components/progress-calculator/component", ["exports", "cac-common/components/progress-calculator/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
      computed = Ember.computed,
      get = Ember.get,
      htmlSafe = Ember.String.htmlSafe;

  var _default = Component.extend({
    layout: _template.default,
    tagName: '',
    current: null,
    total: null,
    percent: computed('current', 'total', function () {
      return 100 * get(this, 'current') / get(this, 'total');
    }),
    progressWidthStyle: computed('percent', function () {
      return htmlSafe("width: ".concat(get(this, 'percent'), "%"));
    })
  });

  _exports.default = _default;
});