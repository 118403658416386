define('ember-data-live-filter-by/filtered-subset', ['exports', 'ember'], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var FilteredSubset = _ember['default'].ArrayProxy.extend({
    init: function init() {
      this._super.apply(this, arguments);

      var _getProperties = this.getProperties('filterByArgs', 'recordArray');

      var filterByArgs = _getProperties.filterByArgs;
      var recordArray = _getProperties.recordArray;

      var _filterByArgs = _slicedToArray(filterByArgs, 1);

      var key = _filterByArgs[0];

      var path = 'recordArray.@each.' + key;
      _ember['default'].defineProperty(this, 'content', _ember['default'].computed(path, function () {
        return this.filterBy.apply(recordArray, filterByArgs);
      }));
    }
  });

  exports['default'] = FilteredSubset;
});