define("cac-common/components/linked-items/empty/component", ["exports", "cac-common/components/linked-items/empty/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component;

  var _default = Component.extend({
    layout: _template.default,
    tagName: '',
    basic: false,
    icon: 'ghost',
    text: "No items found"
  }).reopenClass({
    positionalParams: ['text']
  });

  _exports.default = _default;
});