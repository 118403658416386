define('ember-pusher/initializers/ember-pusher-injections', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(application) {
    application.inject('controller', 'pusher', 'service:pusher');
    application.inject('route', 'pusher', 'service:pusher');
  }

  exports['default'] = {
    name: 'ember-pusher-injections',
    initialize: initialize
  };
});