define("cac-common/components/promising-button/component", ["exports", "ember-data", "cac-common/components/promising-button/template"], function (_exports, _emberData, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
      computed = Ember.computed,
      get = Ember.get;
  var PromiseObject = _emberData.default.PromiseObject;

  var _default = Component.extend({
    layout: _template.default,
    tagName: 'button',
    attributeBindings: ['isDisabled:disabled'],
    isDisabled: false,
    promiseObject: computed('promise', function () {
      var promise = get(this, 'promise');

      if (promise) {
        return PromiseObject.create({
          promise: promise
        });
      } else {
        return null;
      }
    })
  });

  _exports.default = _default;
});