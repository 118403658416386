define('ember-keyboard/utils/trigger-event', ['exports', 'ember', 'ember-keyboard/utils/assign-polyfill', 'ember-keyboard/utils/get-cmd-key', 'ember-keyboard'], function (exports, _ember, _emberKeyboardUtilsAssignPolyfill, _emberKeyboardUtilsGetCmdKey, _emberKeyboard) {
  function _toArray(arr) { return Array.isArray(arr) ? arr : Array.from(arr); }

  var triggerKeyEvent = function triggerKeyEvent(eventType, rawCode) {
    var event = _ember['default'].$.Event(eventType);

    var _rawCode$split = rawCode.split('+');

    var _rawCode$split2 = _toArray(_rawCode$split);

    var code = _rawCode$split2[0];

    var modifiers = _rawCode$split2.slice(1);

    var properties = modifiers.reduce(function (properties, modifier) {
      modifier = modifier === 'cmd' ? (0, _emberKeyboardUtilsGetCmdKey['default'])() : modifier;
      properties[modifier + 'Key'] = true;

      return properties;
    }, {});

    (0, _emberKeyboardUtilsAssignPolyfill['default'])(event, { code: code, keyCode: (0, _emberKeyboard.getKeyCode)(code) }, properties);

    _ember['default'].$(document).trigger(event);
  };

  var triggerKeyDown = function triggerKeyDown(code) {
    triggerKeyEvent('keydown', code);
  };

  var triggerKeyPress = function triggerKeyPress(code) {
    triggerKeyEvent('keypress', code);
  };

  var triggerKeyUp = function triggerKeyUp(code) {
    triggerKeyEvent('keyup', code);
  };

  exports.triggerKeyDown = triggerKeyDown;
  exports.triggerKeyPress = triggerKeyPress;
  exports.triggerKeyUp = triggerKeyUp;
});