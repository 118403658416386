define("cac-common/components/changeset-errors/component", ["exports", "cac-common/components/changeset-errors/template", "cac-common/utils/extract-errors"], function (_exports, _template, _extractErrors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component;

  var _default = Component.extend({
    PRIMARY_ATTRIBUTE_KEY: _extractErrors.PRIMARY_ATTRIBUTE_KEY
  }, {
    layout: _template.default,
    tagName: ''
  }).reopenClass({
    positionalParams: ['changeset', 'property']
  });

  _exports.default = _default;
});