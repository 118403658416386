define("cac-components/components/image-slide/component", ["exports", "cac-components/components/image-slide/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['image-slide'],
    images: null,
    description: null,
    limit: 100
  });

  _exports.default = _default;
});