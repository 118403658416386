define("cac-components/components/image-slide/thumbs/thumb/component", ["exports", "cac-components/components/image-slide/thumbs/thumb/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    layout: _template.default,
    actions: {
      stopPropagation: function stopPropagation(e) {
        e.stopPropagation();
      }
    }
  });

  _exports.default = _default;
});