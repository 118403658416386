define("cac-components/components/linked-items/infinity/component", ["exports", "cac-components/components/linked-items/infinity/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
      service = Ember.inject.service,
      get = Ember.get,
      set = Ember.set;

  var _default = Component.extend({
    layout: _template.default,
    tagName: '',
    infinity: service(),
    _isLoading: false,
    actions: {
      load: function load(items) {
        var _this = this;

        set(this, '_isLoading', true);
        get(this, 'infinity').infinityLoad(items).finally(function () {
          set(_this, '_isLoading', false);
        });
      }
    }
  });

  _exports.default = _default;
});