define("cac-common/mixins/data-adapter-mixin", ["exports", "ember-simple-auth/mixins/data-adapter-mixin"], function (_exports, _dataAdapterMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_dataAdapterMixin.default, {
    authorizer: 'authorizer:application',
    getAuthHeaders: function getAuthHeaders() {
      var data = Ember.get(this, 'session.data.authenticated');
      var accessToken = data['access_token'];
      var tokenId = data['token_id'];
      return {
        'Authorization': "Bearer ".concat(accessToken, "; token_id=").concat(tokenId)
      };
    }
  });

  _exports.default = _default;
});