define('ember-transition-helper/utils/transform-query-params', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = transformQueryParams;
  // Transforms query-params template helper to what transitonTo expects
  function transformQueryParams(transitionArgs) {
    return !transitionArgs || typeof transitionArgs.map !== 'function' ? transitionArgs : transitionArgs.map(function (arg) {
      if (arg && arg.isQueryParams) {
        return { queryParams: arg.values };
      } else {
        return arg;
      }
    });
  }
});