define("cac-components/components/x-tabs/component", ["exports", "cac-components/components/x-tabs/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    default: null,
    active: null,
    didReceiveAttrs: function didReceiveAttrs() {
      this.set('active', this.get('default'));
    }
  });

  _exports.default = _default;
});