define("cac-components/utils/progress-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    loaded: 0,
    total: 0,
    notify: function notify(evt) {
      Ember.set(this, 'loaded', evt.loaded);
      Ember.set(this, 'total', evt.total);
    },
    percentage: Ember.computed('loaded', 'total', function () {
      if (Ember.get(this, 'total')) {
        return Math.round(Ember.get(this, 'loaded') / Ember.get(this, 'total') * 100);
      } else {
        return 0;
      }
    })
  });

  _exports.default = _default;
});