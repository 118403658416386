define('lodash/_stringToPath', ['exports', 'lodash/_memoizeCapped'], function (exports, _lodash_memoizeCapped) {

  /** Used to match property names within property paths. */
  var rePropName = /[^.[\]]+|\[(?:(-?\d+(?:\.\d+)?)|(["'])((?:(?!\2)[^\\]|\\.)*?)\2)\]|(?=(?:\.|\[\])(?:\.|\[\]|$))/g;

  /** Used to match backslashes in property paths. */
  var reEscapeChar = /\\(\\)?/g;

  /**
   * Converts `string` to a property path array.
   *
   * @private
   * @param {string} string The string to convert.
   * @returns {Array} Returns the property path array.
   */
  var stringToPath = (0, _lodash_memoizeCapped['default'])(function (string) {
    var result = [];
    if (string.charCodeAt(0) === 46 /* . */) {
        result.push('');
      }
    string.replace(rePropName, function (match, number, quote, subString) {
      result.push(quote ? subString.replace(reEscapeChar, '$1') : number || match);
    });
    return result;
  });

  exports['default'] = stringToPath;
});